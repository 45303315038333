import './App.css';

import * as React from 'react';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { Button, Paper, TextField } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';


function App() {

  const theme = createTheme();

  const [zip, setZip] = useState('');
  const [tduID, setTduID] = useState('');
  const [tdus, setTdus] = useState([]);
  const [plans, setPlans] = useState([]);

  // ZipTDU component
  // Renders a field to enter the zip code
  // and a button to submit the zip code
  // then calls the API to get the TDUs
  // and renders the TDUs
  function ZipTDU() {
    return (
      <React.Fragment>
        <ZipCode />
        <TDU />
      </React.Fragment>
    );
  }

  function handleSubmit(event) {
    event.preventDefault();
    // Retrieve the zip code from the form
    const zip = event.target.elements.zip.value;
    setZip(zip);
    // Retrieve the TDU ID by calling fetchTDU with the zip code
    fetchTDU(zip);

    // const data = new FormData(event.currentTarget);
    // fetchTDU(data.get('zip'));
  }

  // ZipCode component
  // Renders a field to enter the zip code
  // and a button to submit the zip code
  // then calls the API to get the TDUs
  // and renders the TDUs
  function ZipCode() {
    return (
      <Box component="form" onSubmit={handleSubmit}>
        <TextField id="zip" label="Zip Code" variant="outlined" />
        <Button type="submit" variant="contained">Submit</Button>
      </Box>
    );
  }
  function handleChange(event) {
    setTduID(event.target.value);
  }

  function handleTDU(event) {
    event.preventDefault();
    fetchPlans(zip, tduID);
  }

  async function fetchPlans(zip, tduID) {
    // Call the plans api with the zip code and tduID posted in the body as JSON
    const response = await fetch('/api/plans', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ zip: zip, tduID: tduID })
    });
    const data = await response.json();
    setPlans(data);
  }


  // TDU component
  // Renders a list of TDUs based on the React state
  function TDU() {
    if (tdus.length > 0) {
      return (
        <Box component="form" onSubmit={handleTDU}>
          <FormControl fullWidth>
            <InputLabel>TDU</InputLabel>
            <Select
              value={tduID}
              label="TDU"
              onChange={handleChange}
            >
              {tdus.map(tdu => (
                <MenuItem key={tdu.id} value={tdu.id}>
                  {tdu.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button type="submit" variant="contained">Submit</Button>
        </Box>
      );
    }
    else {
      return;
    }
  }

  // Renders a list of plans based on the React state
  function Plans() {
    if (plans.length > 0) {
      return (
        <Box>
          {plans.map(plan => (
            <Paper key={plan.id}>
              <Typography>{plan.plan_name}</Typography>
            </Paper>
          ))}
        </Box>
      );
    }
    else {
      return;
    }
  }

  async function fetchTDU(zip) {
    const response = await fetch('/api/tdu', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: '{"zip": "' + zip + '"}',
    });
    const data = await response.json();
    setTdus(data);
  }

  return (

    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Find the electric plan that's right for you
        </Typography>

        <ZipTDU />
        <Plans />
      </Box>
    </Container>

  );
}



export default App;
